import React, { Component } from 'react';
// import logo from './logo.svg';
// import './App.css';
import './App.scss';

// Import Bootstrap 4
import 'bootstrap/dist/css/bootstrap.css';
// import $ from 'jquery';
// import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';


class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                
                
<nav className="navbar navbar-expand-lg navbar-light bg-light">
    <a className="navbar-brand" href="#О_Компании">
        <img className="logo-image"
            src="/i/logo.png"
            alt="НьюКамертон"
        />
        НьюКамертон
    </a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
            <li className="nav-item">
                <a className="nav-link" href="#Лицензии">Лицензии</a>
                {/*
                <a className="nav-link" href="#Работы_с_Открытыми_ИИИ">Работы с Открытыми Источниками Ионизирующего Излучения</a>
                <a className="nav-link" href="#Работы_с_Закрытыми_ИИИ">Работы с Закрытыми Источниками Ионизирующего Излучения</a>
                <a className="nav-link" href="#Работы_с_Устройствами_генерирующими_излучение">Работы с Устройствами, генерирующими излучение</a>
                */}
                <a className="nav-link" href="#Наши_Клиенты">Наши Клиенты</a>
                <a className="nav-link" href="#Ссылки">Ссылки</a>
                <a className="nav-link" href="#Контакты">Контакты</a>
            </li>
        </ul>
    </div>
</nav>
                    
                    
                    <section id="О_Компании">
                        <div className="body">
                            <h1>НьюКамертон</h1>
                            <p>
                                Научно-производственное общество с ограниченной ответственностью.
                            </p>
                            <p>
                                &#171;<strong>НьюКамертон</strong>&#187; проводит работы по ремонту и техническому обслуживанию генераторов радона и установок по приготовлению концентратов радона. 
                            </p>
                            <p>
                                В &#171;<strong>НьюКамертон</strong>&#187; аккредитована испытательная Лаборатория радиационного контроля на проведение измерений:
                            </p>
                            <ul>
                                <li>мощности дозы рентгеновского, гамма и нейтронного излучения,</li>
                                <li>плотности потока альфа- и бета-частиц</li>
                                <li>индивидуальной дозы внешнего облучения.</li>
                            </ul>
                        </div>
                    </section>
                    
                    
                    
                    
                {/*
                    <section id="Работы_с_Открытыми_ИИИ">
                        <div className="body">
                            <h2 title="Работы с Открытыми Источниками Ионизирующего Излучения">
                                Работы с Открытыми Источниками Ионизирующего Излучения
                            </h2>
                            <p>
                                Генераторы радона ГР-№ТК, установки по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).
                            </p>
                            <ul>
                                <li title="Ремонт Генераторов радона ГР-№ТК, установкок по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).">
                                    Ремонт
                                </li>
                                <li title="Техническое обслуживание Генераторов радона ГР-№ТК, установкок по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).">
                                    Техническое обслуживание
                                </li>
                                <li title="Монтаж Генераторов радона ГР-№ТК, установкок по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).">
                                    Монтаж
                                </li>
                                <li title="Демонтаж Генераторов радона ГР-№ТК, установкок по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).">
                                    Демонтаж
                                </li>
                                <li title="Перелив солей радия-226 Генераторов радона ГР-№ТК, установкок по приготовлению и разливу концентрата радона (Rn-226 и дочерних продуктов распада, активность 1,2 ГБк).">
                                    Перелив солей радия-226
                                </li>
                            </ul>
                        </div>
                    </section>
                */}
                    
                    
                {/*
                    <section id="Работы_с_Закрытыми_ИИИ">
                        <div className="body">
                            <h2 title="Работы с Закрытыми Источниками Ионизирующего Излучения">
                                Работы с Закрытыми Источниками Ионизирующего Излучения
                            </h2>
                            <p>
                                Гамма-установки IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).
                            </p>
                            <ul>
                                <li title="Ремонт Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Ремонт
                                </li>
                                <li title="Зарядка Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Зарядка
                                </li>
                                <li title="Перезарядка Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Перезарядка
                                </li>
                                <li title="Техническое обслуживание Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Техническое обслуживание
                                </li>
                                <li title="Монтаж Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Монтаж
                                </li>
                                <li title="Демонтаж Гамма-установок IBL-437C, PXM, Агат-Р, С, В, Рокус, Тератрон, Микроселектрон (Co-60, Cs-137, активностью 10Ки - 20кКи).">
                                    Демонтаж
                                </li>
                            </ul>
                        </div>
                    </section>
                */}
                    
                    
                {/*
                    <section id="Работы_с_Устройствами_генерирующими_излучение">
                        <div className="body">
                            <h2 title="Работы с Устройствами, Генерирующими Излучение">
                                Работы с Устройствами, Генерирующими Излучение
                            </h2>
                            <p>
                                Рентген-терапевтические аппараты, Рентген-диагностические аппараты, Рентгенаппараты для досмотра багажа и товаров.
                            </p>
                            <ul>
                                <li title="Ремонт Рентген-терапевтических аппаратов, Рентген-диагностических аппаратов, Рентгенаппаратов для досмотра багажа и товаров.">
                                    Ремонт
                                </li>
                                <li title="Техническое обслеживание Рентген-терапевтических аппаратов, Рентген-диагностических аппаратов, Рентгенаппаратов для досмотра багажа и товаров.">
                                    Техническое обслеживание
                                </li>
                                <li title="Монтаж Рентген-терапевтических аппаратов, Рентген-диагностических аппаратов, Рентгенаппаратов для досмотра багажа и товаров.">
                                    Монтаж
                                </li>
                                <li title="Демонтаж Рентген-терапевтических аппаратов, Рентген-диагностических аппаратов, Рентгенаппаратов для досмотра багажа и товаров.">
                                    Демонтаж
                                </li>
                            </ul>
                        </div>
                    </section>
                */}
                    
                    
                    <section id="Лицензии">
                        <header>
                            <h2>Лицензии</h2>
                        </header>
                        <div className="body">
                            <ul id="licenses">
                                <li className="text-center">
                                    <div className="background-container">
                                        <h3>
                                            Министерство по Чрезвычайным Ситуациям<br /> Республики Беларусь
                                        </h3>
                                        <div className="background-for-image">
                                            <img
                                                src="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.1st.800x1100.jpg"
                                            />
                                        </div>
                                        <p>
                                            Специальное разрешение<br />
                                            (лицензия)
                                        </p>
                                        <p>
                                            на право осуществления <i>деятельности в области использования атомной энергии и источников ионизирующего излучения</i>.
                                        </p>
                                        <a
                                            href="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.1st.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 1"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.1st.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 1"
                                            />
                                        </a>
                                        <a
                                            href="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.2nd.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 2"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.2nd.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 2"
                                            />
                                        </a>
                                        <a
                                            href="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.3rd.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 3"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.3rd.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 3"
                                            />
                                        </a>
                                        <a
                                            href="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.4th.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 4"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Emergency_Situations_of_the_Republic_of_Belarus.4th.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления деятельности в области использования атомной энергии и источников ионизирующего излучения, Министерство по Чрезвычайным Ситуациям Республики Беларусь, страница 4"
                                            />
                                        </a>
                                    </div>
                                </li>
                                <li className="text-center">
                                    <div className="background-container">
                                        <h3>
                                            Министерство Здравоохранения<br /> Республики Беларусь
                                        </h3>
                                        <div className="background-for-image">
                                            <img
                                                src="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.1st.800x1100.jpg"
                                            />
                                        </div>
                                        <p>
                                            Специальное разрешение<br />
                                            (лицензия)
                                        </p>
                                        <p>
                                            на право осуществления &quot;<i>Медицинская Деятельность</i>&quot;
                                        </p>
                                        <a
                                            href="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.1st.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 1"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.1st.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 1"
                                            />
                                        </a>
                                        <a
                                            href="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.2nd.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 2"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.2nd.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 2"
                                            />
                                        </a>
                                        <a
                                            href="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.3rd.800x1100.jpg"
                                            title="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 3"
                                        >
                                            <img
                                                src="/i/license/Ministry_of_Health_of_the_Republic_of_Belarus.3rd.80x110.jpg"
                                                alt="Специальное разрешение (лицензия) на право осуществления &quot;Медицинская Деятельность&quot;, Министерство Здравоохранения Республики Беларусь, страница 3"
                                            />
                                        </a>
                                    </div>
                                </li>
                                <li className="text-center">
                                    <div className="background-container">
                                        <h3>
                                            Национальная Система Аккредитации<br /> Республики Беларусь
                                        </h3>
                                        <div className="background-for-image">
                                            <img
                                                src="/i/license/Certificate_of_Accreditation.800x1100.jpg"
                                            />
                                        </div>
                                        <p>
                                            <a 
                                                target="_blank" rel="noopener noreferrer"
                                                href="https://bsca.by/ru/registry-testlab/view?id=6079"
                                                title="Аттестат Белорусского Государственного Центра Аккредитации."
                                            >
                                                Аттестат Аккредитации<br />
                                            </a>
                                            подтверждает, что <strong>лаборатория радиационного контроля</strong><br />
                                            соответствует критериям<br />
                                            Национальной системы аккредитации Республики Беларусь и<br />
                                            аккредитована на соответствие требованиям<br />
                                            <strong>СТБ ИСО/МЭК 17025-2007</strong>
                                        </p>
                                        
                                        <a
                                            href="/i/license/Certificate_of_Accreditation.800x1100.jpg"
                                            title="Аттестат Аккредитации СТБ ИСО/МЭК 17025-2007"
                                        >
                                            <img
                                                src="/i/license/Certificate_of_Accreditation.80x110.jpg"
                                                alt="Аттестат Аккредитации СТБ ИСО/МЭК 17025-2007"
                                            />
                                        </a>
                                    </div>
                                </li>
                            {/*
                                <li className="text-center">
                                    <p>
                                        <strong>
                                            Министерство Здравоохранения Республики Беларусь
                                        </strong>
                                    </p>
                                    <p>
                                        САНИТАРНЫЙ ПАСПОРТ
                                    </p>
                                    <p>
                                        на право работы с источниками ионизирующего излучения (<abbr title="Источники Ионизирующего Излучения">ИИИ</abbr>)
                                    </p>
                                    <a
                                        href="/i/license/Sanitary_Passport_Ministry_of_Health_of_the_Republic_of_Belarus.800x1100.jpg"
                                        title="Санитарный паспорт на право работы с источниками ионизирующего излучения, Министерство Здравоохранения Республики Беларусь"
                                    >
                                        <img
                                            style={{border: '1px solid lightgrey'}}
                                            src="/i/license/Sanitary_Passport_Ministry_of_Health_of_the_Republic_of_Belarus.80x110.jpg"
                                            alt="Санитарный паспорт на право работы с источниками ионизирующего излучения, Министерство Здравоохранения Республики Беларусь"
                                        />
                                    </a>
                                </li>
                            */}
                            </ul>
                            <p className="note">
                                <sup>*</sup> Деятельность, связанная с осуществлением контроля радиоактивного загрязнения, не подлежит лицензированию. <a href="https://chernobyl.mchs.gov.by/kontrol-radioaktivnogo-zagryazneniya/litsenzirovanie/?_ga=2.41621346.1395161672.1566303244-450262980.1566303244" target="_blank" rel="noopener noreferrer">Подребнее на сайте МЧС</a>
                            </p>
                        </div>
                    </section>
                    
                    
                    <section id="Наши_Клиенты" className="clients">
                        <div className="body">
                            <h2>Наши Клиенты</h2>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="http://mhmbel.by/"
                                    title="Совместное общество с ограниченной ответственностью &laquo;Манули Гидравликс Мануфактуринг Бел&raquo;"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/ManuliHydraulis.png"
                                            alt="Совместное общество с ограниченной ответственностью &laquo;Манули Гидравликс Мануфактуринг Бел&raquo;"
                                        />
                                    </div>
                                    <div className="client-nameXX">
                                        Совместное общество с ограниченной ответственностью &laquo;Манули Гидравликс Мануфактуринг Бел&raquo;
                                    </div>
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://ruchaika.by/"
                                    title="Открытое акционерное общество &quot;Ручайка&quot;"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/Ruchaika.png"
                                            alt="Открытое акционерное общество &quot;Ручайка&quot;"
                                        />
                                    </div>
                                    Открытое акционерное общество &quot;Ручайка&quot;
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://www.hospital.brest.by/"
                                    title="УЗ &laquo;Брестская областная клиническая больница&raquo;"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/Hospital_Brest.png"
                                            alt="УЗ &laquo;Брестская областная клиническая больница&raquo;"
                                        />
                                    </div>
                                    УЗ &laquo;Брестская областная клиническая больница&raquo;
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="http://makaenka17med.by/"
                                    title="Республиканский центр медицинской реабилитации и бальнеолечения"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/RCMRB.png"
                                            alt="Республиканский центр медицинской реабилитации и бальнеолечения"
                                        />
                                    </div>
                                    Республиканский центр медицинской реабилитации и бальнеолечения
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://lepelsan.by/"
                                    title="ГУ Лепельский военный санаторий"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/Lepel.png"
                                            alt="ГУ Лепельский военный санаторий"
                                        />
                                    </div>
                                    ГУ Лепельский военный санаторий
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://onco.by/"
                                    title="Учреждение здравоохранения Витебский областной клинический онкологический диспансер"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/ONCO.png"
                                            alt="Учреждение здравоохранения Витебский областной клинический онкологический диспансер"
                                        />
                                    </div>
                                    Учреждение здравоохранения Витебский областной клинический онкологический диспансер
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="http://metropoliten.by/"
                                    title="Государственное предприятие &quot;Минский метрополитен&quot;"
                                >
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/metro.png"
                                            alt="Государственное предприятие &quot;Минский метрополитен&quot;"
                                        />
                                    </div>
                                    Государственное предприятие &quot;Минский метрополитен&quot;
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="http://www.vokb.vitebsk.by/"
                                    title="Витебская областная клиническая больница"
                                >
                                {/*
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/BSCA.png"
                                            alt="Аттестат Белорусского Государственного Центра Аккредитации."
                                        />
                                    </div>
                                */}
                                    Витебская областная клиническая больница
                                </a>
                            </div>
                            
                            <div className="client">
                                <a className="client-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://minsk.rw.by/social_sphere/ozdorovitelnyj_tsentr_talka/obschaja_informatsija/"
                                    title="Оздоровительный центр «Талька»"
                                >
                                {/*
                                    <div className="client-logo-wrapper">
                                        <img className="client-logo-image"
                                            src="/i/clients/ONCO.png"
                                            alt="Оздоровительный центр «Талька»"
                                        />
                                    </div>
                                */}
                                    Оздоровительный центр «Талька»
                                </a>
                            </div>
                        </div>
                    </section>


                    <section id="Ссылки" className="links">
                        <div className="body">
                            <h2>Ссылки</h2>
                            <div className="link">
                                <a className="link-logo"
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://bsca.by/ru/registry-testlab/view?id=6079"
                                    title="Аттестат Белорусского Государственного Центра Аккредитации."
                                >
                                    <div className="link-logo-wrapper">
                                        <img className="link-logo-image"
                                            src="/i/links/BSCA.png"
                                            alt="Аттестат Белорусского Государственного Центра Аккредитации."
                                        />
                                    </div>
                                    Аттестат Белорусского Государственного Центра Аккредитации.
                                </a>
                                
                            </div>
                        </div>
                    </section>
                    
                    
                    <section id="Контакты">
                        <header>
                            <h2>Контакты</h2>
                        </header>
                        <div className="body">
                        
                            <p>
                                <img className="director-image"
                                    src="/i/director.jpg"
                                    alt=""
                                />
                            </p>
                            <p>
                                <strong>Директор: </strong>
                                Мазаник Александр Александрович
                            </p>
                            <p>
                                <strong>Телефон/факс: </strong>
                                <a href="tel:+375(17)399-46-15">+375 (17) 399-46-15</a>
                            </p>
                            <p>
                                <strong>E-mail: </strong>
                                <a href="mailto:new_kamerton@mail.ru">new_kamerton@mail.ru</a>
                            </p>
                            <p>
                                <strong>Почтовый адрес: </strong>
                                г.Минск, а/я 127, 220109.
                            </p>
                            <p>
                                <strong>Юридический адрес: </strong>
                                Республика Беларусь<br />
                                <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/VM17nYtQ7mFRRPDB7">г.Минск, ул.Селицкого, д.113а</a>, помещение 16, каб. 42, 220075.
                            </p>
                            <p>
                                УНП 100097490
                            </p>

                        </div>
                    </section>
                    
                </div>
                
{/*
<h2>Радиационные Устройства</h2>
<ul>
    <li title="Монтаж Радиационных устройств.">Монтаж</li>
    <li title="Наладка Радиационных устройств.">Наладка</li>
    <li title="Ремонт Радиационных устройств.">Ремонт</li>
    <li title="Обслуживание Радиационных устройств.">Обслуживание</li>
    <li title="Диагностирование Радиационных устройств.">Диагностирование</li>
</ul>
    
<h2>Радиационные Установки</h2>

<h3 title="Виды Услуг Радиационных Установок">Услуги</h3>
<ul>
    <li title="Монтаж Радиационных установок.">Монтаж</li>
    <li title="Наладка Радиационных установок.">Наладка</li>
    <li title="Ремонт Радиационных установок.">Ремонт</li>
    <li title="Обслуживание Радиационных установок.">Обслуживание</li>
    <li title="Диагностирование Радиационных установок.">Диагностирование</li>
</ul>

<h3>Виды Радиационных Установок</h3>
<ul>
    <li title="Монтаж Радиационных установок.">гамма - терапевтические аппараты медицинского назначения</li>
    <li title="Наладка Радиационных установок.">генераторы радона</li>
    <li title="Ремонт Радиационных установок.">установки по приготовлению и розливу концентрата радона</li>
    <li title="Обслуживание Радиационных установок.">рентгенодиагностические аппараты</li>
    <li title="Диагностирование Радиационных установок.">рентгенотерапевтические аппараты медицинского назначения</li>
    <li title="Диагностирование Радиационных установок.">рентгеновские установки для досмотра багажа и товаров</li>
</ul>
*/}

{/*
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
        */}
            </div>
        );
    }
}

export default App;
